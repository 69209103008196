<template>
<div class="row">
  <div class="col-md-12 mb-3">

        <span class="mt-2 h4 titulosg">Nuevos Estudiantes</span> <br>
         Registro de Datos Personales

      <div class="bg-white p-4 mt-3">
        <div class="col-md-10">
          <persona :id="idpersona" :tipopersona="tipopersona"></persona>
        </div>
      </div>

  </div>
</div>
</template>

<script>
    import persona from '../personas/CrearEditarPersona'
    import { ref, watch, onMounted } from 'vue'    
    import axios from 'axios'

export default {
    components:{
      persona
    },
    props: ['id'],
    setup(props) {

        const idpersona = ref(props.id)
        const tipopersona = ref('4')

        return {
                 idpersona,
                 tipopersona
                }
        
    }

}
</script>

<style>

</style>
